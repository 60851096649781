<template>
  <nav
    class="right_bg_propertyBar close-right-sidebar-16rem z-40 md:right-0 md:block md:top-0 md:bottom-0 md:overflow-y-auto md:flex-row md:flex-nowrap md:overflow-hidden shadow-xl flex flex-wrap items-center justify-between relative md:w-w-2/12 z-10 py-0"
    v-bind:style="{ right: toggle_right_Sidebar + 'rem' }"
  >
    <div class="">
      <div class="flex height_proper justify-between px-2">
        <div class="righ_side_head_">
          <svg
            class="twobarsRight"
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="4"
            viewBox="0 0 12 4"
            fill="none"
            data-v-6a52651e=""
          >
            <path
              d="M11.3333 0H0.666626V1.33333H11.3333V0ZM0.666626 4H11.3333V2.66667H0.666626V4Z"
              fill="#777777"
              data-v-6a52651e=""
            ></path>
          </svg>
          <p class="sidebar-heading">{{ properties.name }}</p>
        </div>
        <div>
          <svg
            v-on:click="closeNavigation"
            class="cursor-pointer ml-auto mt-0 w-2 left_close_btn"
            style="text-align: right"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="15"
            viewBox="0 0 15 15"
          >
            <path
              id="close_2_"
              data-name="close (2)"
              d="M8.874,7.636,14.715,1.8A.972.972,0,1,0,13.341.421L7.5,6.262,1.659.421A.972.972,0,0,0,.285,1.8L6.126,7.636.285,13.477a.972.972,0,1,0,1.374,1.374L7.5,9.01l5.841,5.841a.972.972,0,0,0,1.374-1.374Zm0,0"
              transform="translate(0 -0.136)"
              fill="#fff"
            />
          </svg>
        </div>
      </div>
    </div>

    <div class="pt-0_5 pr-0_5 pl-0_5 pb-0_5 prop_mx_height">
      <div
        v-on:click="toggle_height_handler_two"
        class="bg-right_ flex bg-black justify-between pt-5 pl-5 pb-4"
        
      >
        <div>
          <p class="sidebar-label lables_with_ico">
            <img src="../../assets/img/note.png" alt="" />
            Appearance
          </p>
        </div>
        <div class="d-flex self-center">
          <p v-if="!isExpandFeatureTwo"><i class="fas fa-angle-down"></i></p>
          <p v-else><i class="fas fa-angle-up"></i></p>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureTwo ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="pb-5 pt-3">
          <div class="flex items-center justify-between mt-3">
            <p class="label_heading">Background Color</p>
            <input
              type="color"
              id="color1"
              class="focus:ring-indigo-500 label_heading h-9 border-0 input-bg block w-5/12 rounded-md font-color"
              @change="changeBackgroundColor($event)"
              :value="backgroundColor"
            />
          </div>
        </div>
      </div>

      <div
        v-bind:style="{ display: isExpandFeatureThree ? '' : 'none' }"
        class="expand-right-sidebar-feature-one"
      >
        <div class="px-3 pb-5 pt-3">
          <div>
            <div class="form-group-checkbox">
              <label class="w-100 flex font-thin">
                <span class="label_heading"> Border </span>
                <input
                  class="ml-auto leading-tight"
                  type="checkbox"
                  @click="toggleBorderVisibiltyHandler()"
                  :checked="properties.borderDisplay"
                />
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>


<script>
import UpDownButtons from "../UpDownButtons/UpDownButtons";

// import { ChevronDownIcon } from "@heroicons/vue/solid";

export default {
  name: "background-widget",
  components: {
    // ChevronDownIcon,
    UpDownButtons,
  },
  props: {
    toggle_right_Sidebar: {
      type: String,
      default: "",
    },
    toggle_right_margin: {
      type: String,
      default: "",
    },
    id: "",
    properties: {},
    closeNavigation: Function,
  },
  data() {
    return {
      isExpandFeatureOne: false,
      isExpandFeatureTwo: true,
      isExpandFeatureThree: false,
      isExpandFeatureFour: false,
      isExpandFeatureFive: false,
      isExpandFeatureSix: false,
      title: this.properties.title,
    };
  },
  computed: {
    darkDark() {
      return this.$store.state.rect.darkmode;
    },
    backgroundColor() {
      console.log("get background color ", this.$store.state.rect.rects[this.idx].backgroundColor )

      return (
        this.$store.state.rect.rects[this.idx].backgroundColor || getComputedStyle(document.documentElement).getPropertyValue('--defaultBgOfBgWidget')
       
      );
    },
  },
  beforeMount() {
    let rects = this.$store.state.rect.rects;

    if (typeof rects != "undefined" && rects.length > 0) {
      for (let i = 0; i < rects.length; i++) {
        if (
          rects[i].widgetId == this.properties.widgetId &&
          rects[i].displayId == this.properties.displayId
        )
          this.idx = i;
      }
    }
    console.log("background : ", this.idx);
  },
  methods: {
    toggleTitleVisibiltyHandler() {
      this.$store.dispatch("rect/toggleTitleVisibility", { id: this.id });
    },
    toggleBorderVisibiltyHandler() {
      this.$store.dispatch("rect/toggleBorderVisibility", { id: this.id });
    },
    toggleAutoHandler() {
      this.$store.dispatch("rect/toggleAutoFit", { id: this.id });
    },

    fontValueHandler(e) {
      this.$store.dispatch("rect/titleFontValue", {
        id: this.id,
        value: e.target.value ? parseInt(e.target.value) : 0,
      });
    },
    colorValueHandler(e) {
      //
      this.$store.dispatch("rect/changeTitleColor", {
        id: this.id,
        value: e.target.value,
      });
    },
    changeTitleHandler(e) {
      this.$store.dispatch("rect/changeTitle", {
        id: this.id,
        value: e.target.value,
      });
    },
    toggle_height_handler_one() {
      if (this.isExpandFeatureOne == true) {
        this.isExpandFeatureOne = false;
      } else {
        this.isExpandFeatureOne = true;
      }
    },
    toggle_height_handler_two() {
      if (this.isExpandFeatureTwo == true) {
        this.isExpandFeatureTwo = false;
      } else {
        this.isExpandFeatureTwo = true;
      }
    },
    toggle_height_handler_three() {
      if (this.isExpandFeatureThree == true) {
        this.isExpandFeatureThree = false;
      } else {
        this.isExpandFeatureThree = true;
      }
    },
    toggle_height_handler_four() {
      if (this.isExpandFeatureFour == true) {
        this.isExpandFeatureFour = false;
      } else {
        this.isExpandFeatureFour = true;
      }
    },
    toggle_height_handler_five() {
      if (this.isExpandFeatureFive == true) {
        this.isExpandFeatureFive = false;
      } else {
        this.isExpandFeatureFive = true;
      }
    },
    toggle_height_handler_six() {
      if (this.isExpandFeatureSix == true) {
        this.isExpandFeatureSix = false;
      } else {
        this.isExpandFeatureSix = true;
      }
    },
    changeBackgroundColor(e) {
      this.$store.dispatch("rect/changeBackgroundColor", {
        displayId: this.properties.displayId,
        widgetId: this.properties.widgetId,
        value: e.target.value,
      });
    },
    dark() {
      this.darkMode = true;
      this.$emit("dark");
    },
    light() {
      this.darkMode = false;
      this.$emit("light");
    },
  },
  mounted() {
    // this.properties.title
    //
  },
};
</script>

<style scoped src="../../assets/styles/propstyle.css">
</style>
